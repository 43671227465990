<template lang="pug">
v-container(grid-list-md)
  v-img(:src="require('@/assets/images/404.svg')")
  v-layout(row wrap text-center)
    v-flex
      h1.section-title Страница не найдена
      router-link.primary--text(to="/") Перейти на главную
</template>

<script>
export default {
};
</script>
